<script setup>
defineProps({
    type: {
        type: String,
        default: "text",
    },
    error: {
        type: String,
        default: null,
    },
    modelValue: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(["update:modelValue"]);

defineOptions({
    inheritAttrs: false,
});
</script>

<template>
    <div class="w-full">
        <input
            :type="type"
            v-bind="$attrs"
            :class="[{ 'border-red-500 border-solid': error }]"
            @input="emit('update:modelValue', $event.target.value)"
        />
        <div
            v-if="error"
            class="text-red-500 text-left italic"
        >
            {{ error }}
        </div>
    </div>
</template>
