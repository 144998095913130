<script setup>
import BaseLayout from "@/js/Layouts/BaseLayout.vue";
import DashboardMenu from "@/js/Layouts/Partials/DashboardMenu.vue";
import DashboardHeader from "@/js/Layouts/Partials/DashboardHeader.vue";

defineProps({
    appName: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
        required: false,
        default: undefined,
    },
});
const currentYear = new Date().getFullYear();
</script>

<template>
    <BaseLayout>
        <DashboardHeader />

        <div class="page-content mt-[90px]">
            <DashboardMenu class="fixed w-[250px] max-h-[80%] mb-[20px]" />

            <div class="ml-[280px]">
                <main>
                    <slot />
                </main>
                <footer class="mt-4">
                    {{ currentYear }} &copy;
                    <a
                        class="text-link"
                        href="https://www.1001concepten.nl/"
                        target="_blank"
                        >{{ appName }}</a
                    >
                </footer>
            </div>
        </div>
    </BaseLayout>
</template>
