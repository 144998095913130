<script setup>
const emits = defineEmits(["click"]);

const click = () => {
    emits("click");
};
</script>

<template>
    <button
        @click="click"
        class="align-middle outline-none focus:outline-none inline-block py-2 px-4 text-base leading-normal rounded"
    >
        <slot />
    </button>
</template>
