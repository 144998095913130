import { createApp, h } from "vue";
import { createI18n } from "vue-i18n";
import { createInertiaApp } from "@inertiajs/vue3";
import { ZiggyVue } from "../../../vendor/tightenco/ziggy/dist/vue.m";
import axios from "axios";
import AuthLayout from "@/js/Layouts/AuthLayout.vue";
import DashboardLayout from "@/js/Layouts/DashboardLayout.vue";
import translations from "@/js/translations.js";
import { fallbackLocale, determineLocale } from "@/js/helpers.js";
import registerIcons from "./iconConfig.js";
import * as Sentry from "@sentry/vue";
import * as Spotlight from '@spotlightjs/spotlight';

// Register axios and set headers
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const i18n = createI18n({
    locale: determineLocale(),
    fallbackLocale: fallbackLocale,
    messages: translations,
});

registerIcons();

// Setup Inertia
createInertiaApp({
    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.vue", { eager: true });
        let page = pages[`./Pages/${name}.vue`];
        page.default.layout = name.startsWith("Auth/") ? AuthLayout : DashboardLayout;
        return page;
    },
    setup({ el, App, props, plugin }) {
        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.browserTracingIntegration(),
            ],
            tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 0,
        });
        if (import.meta.env.DEV && import.meta.env.VITE_SENTRY_SPOTLIGHT === 'true') {
            Spotlight.init();
        }
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(i18n)
            .use(ZiggyVue)
            .mount(el);
    },
});
