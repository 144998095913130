<template>
    <section class="dashboard-block content-block">
        <div
            v-if="$slots.header"
            class="border-b-neutral-300 border-b border-dotted"
        >
            <h3>
                <slot name="header" />
            </h3>
        </div>
        <div>
            <slot />
        </div>
    </section>
</template>

<script setup></script>
