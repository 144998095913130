<template>
    <div class="flex flex-col gap-4">
        <div
            v-if="$slots.header"
            class="border-b-neutral-300 border-b border-dotted"
        >
            <slot name="header" />
        </div>
        <slot />
    </div>
</template>
<script setup></script>
