<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

defineProps({
    name: {
        type: [String, Object],
        required: true,
    },
});

const formatIcon = (name) => {
    if (typeof name === "string") {
        return ["far", name];
    }

    return name;
};
</script>

<template>
    <FontAwesomeIcon :icon="formatIcon(name)" />
</template>
