<script setup>
import { watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import { toast } from "vue3-toastify";

watch(
    () => usePage().props.flash,
    (flash) => {
        if (flash.success) {
            toast.success(flash.success);
        }
    }
);
</script>

<template>
    <div class="w-screen h-screen">
        <slot />
    </div>
</template>
