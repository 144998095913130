<script setup>
defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    checked: {
        type: Boolean,
        required: true,
    },
});
const emits = defineEmits(["update:checked"]);

const toggle = () => {
    emits("update:checked", !props.checked);
};
</script>

<template>
    <div
        class="w-full flex justify-end gap-4"
        @click="toggle"
    >
        <div
            class="base-input inline-flex h-6 w-6 items-center justify-center cursor-pointer"
            :class="$attrs['class']"
            :tabindex="$attrs['tabindex']"
            @keydown.space.prevent="toggle"
        >
            <div
                v-if="checked"
                class="h-4 w-2 border-2 border-t-0 border-l-0 border-green-600 rotate-45"
            />
        </div>
        <span class="cursor-pointer font-semibold">
            <slot />
        </span>
    </div>
</template>
