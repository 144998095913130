<script setup>
import logo from "@/images/logo.png";
import BaseLayout from "@/js/Layouts/BaseLayout.vue";

defineProps({
    appName: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
        required: false,
        default: undefined,
    },
});

const currentYear = new Date().getFullYear();
</script>

<template>
    <BaseLayout>
        <div class="flex flex-col lg:flex-row">
            <div
                class="flex flex-col justify-between login-background-column py-12 px-14 lg:w-1/3 lg:h-screen"
            >
                <div>
                    <img
                        :src="logo"
                        alt=""
                        class="w-[170px] opacity-50"
                    />
                </div>
                <div class="flex-grow flex flex-col justify-center text-white">
                    <h3>{{ appName }}</h3>
                    <h4
                        class="opacity-70 mt-10 mb-14"
                        v-if="subtitle"
                    >
                        {{ subtitle }}
                    </h4>
                </div>
                <div class="text-white opacity-40">&copy; {{ currentYear }} 1001concepten</div>
            </div>

            <div class="text-center lg:w-2/3 flex flex-col justify-center items-center gap-8">
                <slot />
            </div>
        </div>
    </BaseLayout>
</template>

<style scoped>
.login-background-column {
    background-image: url("./../../images/login-bg.png");
    background-size: cover;
}
</style>
