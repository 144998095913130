<script setup>
import LinkBase from "@/js/Components/LinkBase.vue";
import { IconSmall } from "@/js/Components/Icons";

const props = defineProps({
    href: {
        type: String,
        required: true,
    },
    iconName: {
        type: String,
        required: false,
        default: undefined,
    },
});

const active = props.href === window.location.href;
</script>

<template>
    <LinkBase
        :href="href"
        class="flex gap-4 items-baseline"
        :class="{
            'text-primary-600': active,
            'text-neutral-700': !active,
        }"
    >
        <IconSmall
            :name="iconName"
            v-if="iconName"
        />
        <slot />
    </LinkBase>
</template>
