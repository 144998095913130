import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faBoxArchive,
    faFolderOpen,
    faGearComplex,
    faHouse,
    faInbox,
    faOctagonMinus,
    faUniversity,
} from "@fortawesome/pro-regular-svg-icons";

export default function () {
    library.add(faGearComplex);
    library.add(faHouse);
    library.add(faOctagonMinus);
    library.add(faInbox);
    library.add(faBoxArchive);
    library.add(faFolderOpen);
    library.add(faUniversity);
}
