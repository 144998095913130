<script setup>
import { ButtonPrimary, CustomCheckbox, FormRow, TextInput } from "@/js/Components/Inputs/index.js";
import LinkText from "@/js/Components/LinkText.vue";
import { useForm } from "@inertiajs/vue3";

const loginForm = useForm({
    email: null,
    password: null,
    remember: false,
});

const doLogin = () => {
    if (!loginForm.processing) {
        loginForm.clearErrors();
        loginForm.post("/login", {
            onSuccess: () => {
                window.location.href = route("dashboard"); // TODO: use inertiajs redirect once dashboard is converted
            },
        });
    }
};
</script>

<template>
    <h3 class="text-4xl">{{ $t("auth.login") }}</h3>
    <div class="w-full max-w-[450px]">
        <form @submit.prevent="doLogin">
            <FormRow>
                <TextInput
                    type="email"
                    name="email"
                    :placeholder="$t('auth.email')"
                    class="h-16 bg-neutral-100 px-5"
                    v-model="loginForm.email"
                    :error="loginForm.errors.email"
                    required
                    tabindex="1"
                />
            </FormRow>
            <FormRow>
                <TextInput
                    type="password"
                    name="password"
                    :placeholder="$t('auth.password')"
                    class="h-16 bg-neutral-100 px-5"
                    v-model="loginForm.password"
                    :error="loginForm.errors.password"
                    required
                    tabindex="2"
                />
            </FormRow>
            <FormRow>
                <CustomCheckbox
                    class="bg-neutral-100"
                    v-model:checked="loginForm.remember"
                    tabindex="3"
                >
                    {{ $t("auth.remember_me") }}
                </CustomCheckbox>
            </FormRow>
            <FormRow>
                <LinkText :href="route('password.request')">
                    {{ $t("auth.forgot_password") }}
                </LinkText>
                <ButtonPrimary
                    class="font-medium text-md sm:px-11 h-[50px]"
                    :disabled="loginForm.processing"
                    tabindex="4"
                >
                    {{ $t("auth.login") }}
                </ButtonPrimary>
            </FormRow>
        </form>
    </div>
</template>
