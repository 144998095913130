<script setup>
import { ButtonPrimary, FormRow, TextInput } from "@/js/Components/Inputs/index.js";
import LinkText from "@/js/Components/LinkText.vue";
import { useForm } from "@inertiajs/vue3";

const formData = useForm({
    email: null,
});

const doSubmit = () => {
    if (!formData.processing) {
        formData.clearErrors();
        formData.post(route("password.email"));
    }
};
</script>

<template>
    <h3 class="text-4xl">{{ $t("auth.forgot_my_password") }}</h3>
    <div class="w-full max-w-[450px]">
        <form @submit.prevent="doSubmit">
            <FormRow>
                <TextInput
                    type="email"
                    name="email"
                    :placeholder="$t('auth.email')"
                    class="h-16 bg-neutral-100 px-5"
                    v-model="formData.email"
                    :error="formData.errors.email"
                    required
                    tabindex="1"
                />
            </FormRow>
            <FormRow>
                <LinkText :href="route('login')"> {{ $t("auth.back_to_login") }} </LinkText>
                <ButtonPrimary
                    class="font-medium text-md sm:px-11 h-[50px]"
                    :disabled="formData.processing"
                    tabindex="2"
                >
                    {{ $t("auth.send_password_reset_link") }}
                </ButtonPrimary>
            </FormRow>
        </form>
    </div>
</template>
